








  import {Component, Vue} from "vue-property-decorator";
  import HeaderItems from "./components/HeaderItems.vue";
  import {liffInit} from "./plugins/liff"

  @Component({
      components:{
          HeaderItems,
  },
  })
  export default class App extends Vue{

  created(){
    liffInit(process.env.VUE_APP_LIFF_ID);
  }
}
