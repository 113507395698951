import Liff from "@line/liff";

function isProduction(): boolean {
  return process.env.NODE_ENV === "production";
}

export async function liffInit(liffId: string): Promise<void> {
  if (isProduction()) {
    await Liff.init({ liffId: liffId }).catch((error) => {
      console.log("LIFF init error :" + error);
    });
    //lineからのアクセスだとTrue,それ以外だとfalse
    //loginしたら登録画面に遷移
    if (!Liff.isInClient() && !Liff.isLoggedIn()) {
      window.alert("LINEアカウントにログインしてください。");
      Liff.login();
    }
  }
}

export async function getProfile(): Promise<Profile> {
  if (isProduction()) {
    return await Liff.getProfile();
  }
  return await new MockProfile(
    process.env.VUE_APP_DEV_PROFILE_USER_ID,
    "test user",
    "",
    ""
  );
}

export async function closeWindow() {
    return await Liff.closeWindow();
}

interface Profile {
  userId: string;
  displayName: string;
  pictureUrl?: string;
  statusMessage?: string;
}

class MockProfile implements Profile {
  userId: string;
  displayName: string;
  pictureUrl?: string | undefined;
  statusMessage?: string | undefined;

  constructor(
    userId: string,
    displayName: string,
    picutureUrl: string | undefined,
    statusMessage: string | undefined
  ) {
    this.userId = userId;
    this.displayName = displayName;
    this.pictureUrl = picutureUrl;
    this.statusMessage = statusMessage;
  }
}
