







import {Component, Vue} from "vue-property-decorator";
import Title from "../components/Title.vue";
import HotSpringReservationForm from "../components/HotSpringReservationForm.vue";

@Component({
    components:{
        Title,
        HotSpringReservationForm,
    }
})
export default class HotSpringReservation extends Vue{
    title = "予約フォーム"

    created() {
        document.title = this.title
    }
}

