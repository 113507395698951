













































    import {Component, Vue} from "vue-property-decorator";
    import axios from 'axios'
    //@ts-ignore
    import Datepicker from 'vuejs-datepicker'
    //@ts-ignore
    import {ja} from 'vuejs-datepicker/dist/locale'
    import moment from 'moment'
    import { getProfile, closeWindow } from "../plugins/liff"
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";
    import VModal from "vue-js-modal";
    import firebase from "firebase";
    import "firebase/functions";


//vue-loading-overlayはTypeScript対応してない為
    Vue.component("Loading",Loading)

    Vue.use(VModal)

    @Component({
        components:{
            Datepicker,
        }
    })

    export default class HotSpringReservationForm extends Vue{
        
        selectPeopleNumber: number  = 1
        postData: any = {
            userLineId: "",
            date: "",
            usageTime: "",
            hotSpringType: "",
            peopleNumber: 1,
            nameList: [],
            phoneNumber: "",
        }
        //datePickerの設定
        placeholder: string = "希望日を選択してください"
        date: any = ""
        ja: string = ja
        DatePickerFormat: string = 'yyyy/MM/dd'
        //---------------------
        usageTimeStartList: string[] = [
                   ]
        hotSpringTypeList: string[] = [
           
        ]
        isLoading: boolean = false
        errorMessage:string = ''

        async mounted(){
            await this.changeStateLoading(true)
            await this.signInAnonymously()
            await this.getConfig()
            await this.changeStateLoading(false)
        }

        async signInAnonymously(){
            await firebase.auth().signInAnonymously().catch(function(error){
                const errorCode = error.code;
                const errorMessage = error.message;
            })
        }

        async getConfig(){
            await firebase.functions().httpsCallable('config')({})
            .then((config:any) => {
                this.hotSpringTypeList = config.data.hotSpringTypeList,
                this.usageTimeStartList = config.data.usageTimeStartList
                }).then(() => {
                   // this.isLoading = false
                })
        }

        changeStateLoading(state:boolean){
            this.isLoading = state
        }

        async getLineProfile(){
            const profile = await getProfile();
            this.postData.userLineId = profile.userId;
        }

        customFormatter(date:string){
            return moment(date).format('YYYY/MM/DD')
        }

        //date変数を経由してpostData.dateに入れてるいのは、テキストボックスに日付が反映されないため
        pikerClosedChange(){
            this.postData.date = moment(this.date).format('YYYY/MM/DD')
        }
        
        changePeopleNumber(selectPeopleNumber:number){
            this.postData.peopleNumber = selectPeopleNumber
            this.selectPeopleNumber = selectPeopleNumber
            this.postData.nameList = this.postData.nameList.slice(0, selectPeopleNumber)
        }

        async hotSpringReservationRegister(){
            this.isLoading = true
            await this.getLineProfile()
            firebase.auth().onAuthStateChanged((user) => {
                if(user){
                    firebase.functions().httpsCallable('reserve')(this.postData)
                    .then(() => {
                        this.isLoading = false
                        closeWindow()
                    })
                    .catch(error => {
                        this.isLoading = false
                        const errorDataList = error.details;
                        if (errorDataList.length > 0 && errorDataList[0].message) {
                            this.errorMessage = errorDataList[0].message 
                            this.$modal.show('modal') 
                        } else {
                            this.errorMessage = "エラーが発生し、正しく処理が行えませんでした"
                            this.$modal.show('modal') 
                        }
                    })
                }
            })
        }

        hide(){
            this.$modal.hide('modal') 
        }


    }
