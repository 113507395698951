






import {Component, Vue} from "vue-property-decorator";
import Licence from "./Licence.vue";

@Component({
    components:{
        Licence,
},
})

export default class HeaderItems extends Vue{
}
