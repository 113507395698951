import Vue from 'vue'
import VueRouter, { RouteConfig, RouterOptions } from 'vue-router'
import HotSpringReservation from '../views/HotSpringReservation.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'HotSpringReservation',
    component: HotSpringReservation
  },
]

//URLｎドキュメントルートが/#/となるのでhistoryにしてる
const router = new VueRouter({
  mode: 'history',
  routes,
} as RouterOptions)

export default router
